<template>
<div v-if="perms.includes('Facturation Clients') || perms.includes('Admin')">
     <div v-if="loading">
      <div id="loading-bg">
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div> 
  <section  class="invoice-add-wrapper"  v-else >
    <b-row class="invoice-add">
<!-- {{rows.Products}}
{{FactureData.produits}} -->
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent id="formdata">
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <!-- <logo /> -->
                    <h4 class="text-primary invoice-logo">
                       Client :
                    </h4>
                  </div>
                  <b-card-text class="mb-25">
                    {{rows.NomSociete}} 
                  </b-card-text>
                  <b-card-text class="mb-25">
                    <b>ICE :</b> {{rows.Ice}}
                  </b-card-text>
                  <b-card-text class="mb-0">
                    <b>Tél / Adresse :</b> {{rows.phone}} / {{rows.adresse}}
                  </b-card-text>
                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Devis N°
                    </h4>
                     
                      <b-form-input
                        id="invoice-data-id"
                       :value="rows.NumDevis"
                       disabled                      
                      />
                  </div>
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Date:
                    </h4>
                    <b-form-input
                        id="invoice-data-id"
                       :value="StringTodate(rows.DateDevis)"
                       disabled                      
                      />
                    <!-- <flat-pickr
                    format="MM/DD/yyyy"
                     :value="rows.DateDevis"
                      class="form-control invoice-edit-input"
                    /> -->
                  </div>
                  <!-- <div class="d-flex align-items-center">
                    <span class="title">
                      Due Date:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.dueDate"
                      class="form-control invoice-edit-input"
                    />
                  </div> -->
                </div>
              </div>
            </b-card-body>


          <b-table-lite
            responsive
            :items="rows.Products"
            :fields="['ref_prod', 'Nom', 'quantite', 'Prix_Vente','Total','tva','TTC']"
          >
            <template #cell(ref_prod)="data">
              <b-card-text class="font-weight-bold mb-25">
                <b>{{ data.item.ref_prod }}</b>
              </b-card-text>
            </template>
            <template #cell(quantite)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.qnt }}
              </b-card-text>
            </template>

            <template #cell(Prix_Vente)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.Prix_Vente }}
              </b-card-text>
            </template>

             <template #cell(Total)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.tot }}
              </b-card-text>
            </template>
            <template #cell(tva)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.tva }}
              </b-card-text>
            </template>
             <template #cell(TTC)="data">
              <b-card-text class="font-weight-bold mb-25">
               {{ TwoDigits(data.item.tot*(1+(data.item.tva/100))) }}
              </b-card-text>
            </template>
            </b-table-lite>
                
             
            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
            
                <!-- <b-col
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1">
                <b-card-body class="pt-0">
                 <span class="font-weight-bold">Total H.T: </span>
               <b-form-input
                    v-model="totalHT"        
                    type="number"
                    class="mb-2"
                              />
                </b-card-body>
                </b-col> -->
                 <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                 
                </b-col>
                 <!-- <b-col
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                 <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">NET A PAYER : </span>
               <b-form-input
                    v-model="totalNET"      
                    type="number"
                    class="mb-2"
                              />
                 </b-card-body>
                </b-col> -->
                 <!-- <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex "
                  order="2"
                  order-md="1"
                >
                    <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">La présence facture a la somme de  </span>
              <b-form-textarea v-model="somme" placeholder="CINQ MILLE DHS Z2RO CTS" />
            </b-card-body>
                 </b-col> -->
                <!-- Col: Total -->
                <!-- <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                  <b-card-body class="invoice-padding pt-0">
                   <span class="font-weight-bold">Mode de reglement :  </span>
                  <b-form-input
                    id="invoice-data-sales-person"
                    placeholder="Espece/Cheque"
                    v-model="reglemment"
                  />
                  </b-card-body>
                </b-col> -->
                  <!-- <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                ></b-col> -->
                 <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper" style="max-width: 18rem;">
                    
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total H.T: 
                      </p>
                      <p class="invoice-total-amount">
                      {{rows.TotalHT}} DHs
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total TVA:
                      </p>
                      <p class="invoice-total-amount">
                      {{TwoDigits(rows.TVA)}} 
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total T.T.C:
                      </p>
                      <p class="invoice-total-amount">
                      {{TwoDigits((rows.TotalNet)/(1-(rows.remise/100)))}} DHs
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Remise:
                      </p>
                      <p class="invoice-total-amount">
                        {{rows.remise}} %
                      </p>
                    </div>
                   
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total NET A PAYER:
                      </p>
                      <p class="invoice-total-amount">
                       {{rows.TotalNet}}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
            <!-- Note -->
            
          </b-card>
        </b-form>
      </b-col>
 
      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2" >

        <b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            title="CTRL+P"
           @click="DevisPDF(rows.NumDevis)"
          >
            Télecharger
          </b-button>

         
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Imprimer
          </b-button> -->

          <!-- Button: Edit -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :to="{ name: 'devis-update', params: { id: $route.params.id } }"
          >
            Modifier Devis
          </b-button>
      
        </b-card>

       
      </b-col>
    </b-row>
  </section>
</div>
<div v-else>
  <not-authoriz></not-authoriz>
</div>
</template>

<script>
import config from '@/config'
import moment from 'moment';
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, 
  BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,BTableLite,
  BMedia, BMediaAside, BMediaBody, BLink,BImg
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import NotAuthoriz from '../NotAuthoriz.vue'

// import invoiceStoreModule from './invoiceStoreModule'
// import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,BTableLite,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BMedia, BMediaAside, BMediaBody, BLink,BImg,
    flatPickr,
    vSelect,
    Logo,NotAuthoriz
    // InvoiceSidebarAddNewCustomer,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  data(){
      return{
        perms:[],
          loading: false,
          clients:[],
          Produits:[],
          rows:{},
          NumDevis:'',
          totalHT:0,
          totalNET:0,
          tva:0,
          DateDevis:'',
          remise:0,
          TTC:0,
          prod:[],
          newProdc:[],


      }
  },
  mounted() {
    
         this.perms=JSON.parse(localStorage.getItem('access'));
      this.$http.get(`${config.API_BASE_URL}/api/auth/getAllClient`).then(res => { this.clients = res.data });
      // this.getProduct();
      this.loading = true
    this.$http.get(`${config.API_BASE_URL}/api/auth/getProductDevis/${router.currentRoute.params.id}`).then(res => { this.rows = res.data, this.loading = false});
    this.$mousetrap.bind('ctrl+p', this.logIt);
  },
  
  
 
 
  methods: {
      TwoDigits(val){
            return parseFloat(val).toFixed(2);
      },
      StringTodate(val){
        return moment(val).format('DD/MM/YYYY');
      },
      logIt(e) {
        this.$http.get(`${config.API_BASE_URL}/api/auth/GetDevisPDF/${router.currentRoute.params.id}`,{responseType: 'arraybuffer'})
                .then(response => { 
                  //  console.log(response.data)
                    let blob = new Blob([response.data], { type: 'application/pdf' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'Devis_N'+'.pdf'
                    link.click()
                })
        return false;
      },
      DevisPDF(Num){
          this.$http.get(`${config.API_BASE_URL}/api/auth/GetDevisPDF/${router.currentRoute.params.id}`,{responseType: 'arraybuffer'})
            .then(response => { 
                let blob = new Blob([response.data], { type: 'application/pdf' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'Devis_N'+Num+'.pdf'
                link.click()
            })
      },
      removeItemProduct(data,item){
        //   console.log(data[item].id)
        //   console.log(item)
        this.$swal.fire({
            text: "Vous voulez supprimer ce produit ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!'
            }).then((result) => {
             if (result.value) {
                  this.$http.post(`${config.API_BASE_URL}/api/auth/DeleteProDevis/${router.currentRoute.params.id}/${data[item].id}`)
                    .then(res=> {
                        if(res.data=='success'){
                        this.$swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Produit est bien supprimé',
                        showConfirmButton: false,
                        timer: 1500  })
                        data.splice(item, 1)
                        let result=[];
                        result=this.FactureData.produits.concat(this.rows.Products);
                        this.TTC=0;
                        for (let i = 0; i < result.length; i++) {
                            this.TTC+=parseFloat((result[i].Prix_Vente*result[i].qnt)*(1+(result[i].tva/100)));
                            }  
                        }else{
                            this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Vous pouvez pas supprimer ce client, Il a une facture. '
                            });
                        }    
                    })
              
                }              
            })
        
      },
      CalcTTC(item1,item2){
          let result=[];
          result=item1.concat(item2);
        this.TTC=0;
        for (let i = 0; i < result.length; i++) {
           this.TTC+=parseFloat((result[i].Prix_Vente*result[i].qnt)*(1+(result[i].tva/100)));

            }
        //   console.log(this.TTC)
      },
      
  
  
      getProduct(){
          this.$http.get(`${config.API_BASE_URL}/api/auth/getProducts`)
      .then(res => { this.Produits = res.data })
      },
      addFacture(){
            let data = new FormData;
            console.log(this.FactureData.produits)
          data.append('products', JSON.stringify(this.rows.Products.concat(this.FactureData.produits)));
          data.append('remise', this.rows.remise);
          data.append('DateDevis', moment(String(this.rows.DateDevis)).format('DD/MM/YYYY'));
        //   console.log(Object.fromEntries(data))
          this.$http.post(`${config.API_BASE_URL}/api/auth/EditDevis/${router.currentRoute.params.id}`,data)
            .then(res => { 
                console.log(res.data)
                if(res.data=='success'){
                    this.$swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Votre facture est bien ajoutée',
                  showConfirmButton: false,
                  timer: 1000 })
                //   document.getElementById("formdata").reset();
                this.$router.push('/DevisClient');
                  }else{
                      this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
              })
                } })

        
        //   AddFactFR
      },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.FactureData.produits.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))
    this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
      
    },
    removeItem(index) {
      this.FactureData.produits.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
        let result=[];
        result=this.FactureData.produits.concat(this.rows.Products);
        this.TTC=0;
        for (let i = 0; i < result.length; i++) {
           this.TTC+=parseFloat((result[i].Prix_Vente*result[i].qnt)*(1+(result[i].tva/100)));
            }
    },
    
  },

  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    // if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })


    const itemFormBlankItem = {
      id:0,
      Nom: null,
      Prix_Vente: 0,
      qnt: 0,
      tva:0,
      tot:0,
    }
   
    const FactureData= ref({
        produits :[JSON.parse(JSON.stringify(itemFormBlankItem))],
    })
    const invoiceData = ref({
      id: 5037,
      client: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
    })

    
     const updateItemForm = (index, val) => {
      const { Prix_Vente, id, Nom ,tva} = val
      FactureData.value.produits[index].Prix_Vente = Prix_Vente
      FactureData.value.produits[index].id = id
      FactureData.value.produits[index].Nom = Nom
      FactureData.value.produits[index].tva = tva
    //   this.TTC=this.TwoDigits((Prix_Vente * qnt)*(1+(tva/100)))
    //   FactureData.value.produits[index].total = Prix_Achat*quantite
    
    }
   const printInvoice = () => {
      window.print()
    }

    // console.log(this.rows.Products)

    return {
      FactureData,
      invoiceData,
       printInvoice,
      updateItemForm,
      itemFormBlankItem,
    }
  },
}
</script>


<style scoped>
.media-aside.align-self-start {
    display: grid;
}
.invoice-add .invoice-total-wrapper[data-v-cc5561c2] {
    width: 100%;
    max-width: 18rem;
}
</style>
<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .row.content-header {
    display: none;
}
.invoice-actions.mt-md-0.mt-2.col-md-4.col-xl-3.col-12 {
    display: none;
}
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
.col-md-8.col-xl-9.col-12 {
    max-width: 100%;
}
.col-xl-9 {
    flex: 0 0 100%;
    max-width: 100%;
}
  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 100% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
